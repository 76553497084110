<template>
  <div class="barcode-overlay">
    <!-- Video container only shown for web implementation -->
    <div v-if="isWeb" class="video-container">
      <video id="barcode-scanner-video" autoplay playsinline></video>
    </div>
    <!-- Simple floating header with logo and instructions -->
    <div class="barcode-header">
      <div class="d-flex">
        <div class="barcode-logo mr-3 ml-auto">
          <img src="img/brand/trakk-logo.png" alt="Trakk Logo" />
        </div>
        <div class="mr-auto">
          <h4>QR / BARCODE SCAN</h4>
        </div>
      </div>
      <div>Position the code in the camera view.</div>
    </div>
    
    <!-- Floating cancel button -->
    <div class="barcode-cancel">
      <b-button class="btn" size="lg" variant="info" @click="close">
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
import Alert from '@/mixins/alert';
import scan from '@/mixins/scan';

export default {
  name: "BarcodeOverlay",
  mixins: [Alert, scan],
  data() {
    return {
      scanCode: ''
    };
  },
  computed: {
    isScannerActive() {
      return document.querySelector('body')?.classList.contains('barcode-scanner-active');
    },
    isWeb() {
      // Use the $device mixin to check if we're in a web environment
      return this.$device && this.$device.platform === 'web';
    }
  },
  methods: {
    close() {
      // Stop the barcode scanner when the modal is closed
      // Use the stopBarcodeScan method from the scan mixin
      this.$stopBarcodeScan();
    }
  }
};
</script>

<style scoped>
/* All styles moved to barcodeScanner.scss */
</style>
