import { render, staticRenderFns } from "./BarcodeOverlay.vue?vue&type=template&id=38809e7a&scoped=true"
import script from "./BarcodeOverlay.vue?vue&type=script&lang=js"
export * from "./BarcodeOverlay.vue?vue&type=script&lang=js"
import style0 from "./BarcodeOverlay.vue?vue&type=style&index=0&id=38809e7a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38809e7a",
  null
  
)

export default component.exports